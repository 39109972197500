import { Label } from "..";
import Switch from "./Switch";
import { classNames } from "@billy/lib";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { ReactNode } from "react";

type Props = {
  children?: ReactNode;
  title: string;
  description?: string;
  checked: boolean;
  disabled?: boolean;
  onCheckedChange?: (checked: boolean) => void;
  "data-testid"?: string;
  reverse?: boolean;
  image?: string;
};

function SettingsToggle({
  checked,
  onCheckedChange,
  description,
  title,
  children,
  disabled,
  reverse,
  image,
  ...rest
}: Props) {
  const [animateRef] = useAutoAnimate<HTMLDivElement>();

  return (
    <>
      <div className="flex w-full flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
        <fieldset className="block w-full flex-col sm:flex">
          <div>
            <div
              className={classNames(
                "flex ",
                reverse ? "justify-between flex-row-reverse" : "space-x-3"
              )}
            >
              <Switch
                data-testid={rest["data-testid"]}
                fitToHeight={true}
                checked={checked}
                onCheckedChange={onCheckedChange}
                disabled={disabled}
              />

              <div className="flex">
                {image ? (
                  <div className="inline-block mr-3 h-auto w-10 rounded-sm">
                    <img src={image} alt={title} />
                  </div>
                ) : null}
                <div>
                  <Label className="text-sm font-semibold leading-none text-black">
                    {title}
                  </Label>
                  {description && (
                    <p className="-mt-1.5 text-sm leading-normal text-gray-500">
                      {description}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          {children && (
            <div ref={animateRef}>
              {checked && <div className="mt-4">{children}</div>}
            </div>
          )}
        </fieldset>
      </div>
    </>
  );
}

export default SettingsToggle;

import { showToast, Skeleton } from "../../../";
import { HintsOrErrors } from "./HintOrErrors";
import { Label } from "./Label";
import { classNames } from "@billy/lib";
import { getErrorFromUnknown } from "@billy/lib/errors";
import { FiX } from "@billy/ui/components/icon";
import React, {
  forwardRef,
  ReactElement,
  Ref,
  ReactNode,
  useId,
  useState,
} from "react";
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
  useFormContext,
} from "react-hook-form";

type FormProps<T extends object> = {
  form: UseFormReturn<T>;
  handleSubmit: SubmitHandler<T>;
} & Omit<JSX.IntrinsicElements["form"], "onSubmit">;

const PlainForm = <T extends FieldValues>(
  props: FormProps<T>,
  ref: Ref<HTMLFormElement>
) => {
  const { form, handleSubmit, ...passThrough } = props;

  return (
    <FormProvider {...form}>
      <form
        ref={ref}
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();
          form
            .handleSubmit(handleSubmit)(event)
            .catch((err) => {
              showToast(`${getErrorFromUnknown(err).message}`, "error");
            });
        }}
        {...passThrough}
      >
        {props.children}
      </form>
    </FormProvider>
  );
};

export const Form = forwardRef(PlainForm) as <T extends FieldValues>(
  p: FormProps<T> & { ref?: Ref<HTMLFormElement> }
) => ReactElement;

type AddonProps = {
  children: React.ReactNode;
  isFilled?: boolean;
  className?: string;
  error?: boolean;
};

const Addon = ({ isFilled, children, className, error }: AddonProps) => (
  <div
    className={classNames(
      "addon-wrapper h-9 border border-gray-300 px-3",
      isFilled && "bg-gray-100",
      className
    )}
  >
    <div
      className={classNames(
        "flex h-full flex-col justify-center text-sm",
        error && "text-red-900"
      )}
    >
      <span className="whitespace-nowrap py-2.5">{children}</span>
    </div>
  </div>
);

type InputProps = JSX.IntrinsicElements["input"] & { isFullWidth?: boolean };

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { isFullWidth = true, ...props },
  ref
) {
  return (
    <input
      {...props}
      ref={ref}
      className={classNames(
        "mb-2 block h-9 rounded-md border border-gray-300 py-2 px-3 text-sm placeholder:text-gray-400 hover:border-gray-400 focus:border-neutral-300 focus:outline-none focus:ring-2 focus:ring-neutral-800 focus:ring-offset-1",
        isFullWidth && "w-full",
        props.className
      )}
    />
  );
});

type InputFieldProps = {
  label?: ReactNode;
  hint?: ReactNode;
  hintErrors?: string[];
  addOnLeading?: ReactNode;
  addOnSuffix?: ReactNode;
  inputIsFullWidth?: boolean;
  addOnFilled?: boolean;
  addOnClassname?: string;
  error?: string;
  labelSrOnly?: boolean;
  containerClassName?: string;
  hideError?: boolean;
  t?: (key: string) => string;
} & React.ComponentProps<typeof Input> & {
    labelProps?: React.ComponentProps<typeof Label>;
    labelClassName?: string;
  };

export const TextField = forwardRef<HTMLInputElement, InputFieldProps>(
  function TextField(props, ref) {
    return <InputField ref={ref} {...props} />;
  }
);

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  function InputField(props, ref) {
    const id = useId();
    // const { t: _t, isLocaleReady, i18n } = useLocale();
    // const t = props.t || _t;
    const name = props.name || "";
    const {
      // label = t(name),
      label,
      labelProps,
      labelClassName,
      // placeholder = isLocaleReady && i18n.exists(name + "_placeholder") ? t(name + "_placeholder") : "",
      placeholder,
      className,
      addOnLeading,
      addOnSuffix,
      addOnFilled = true,
      addOnClassname,
      inputIsFullWidth,
      hint,
      type,
      hintErrors,
      labelSrOnly,
      containerClassName,
      readOnly,
      error,
      hideError,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      // t: __t,
      ...passThrough
    } = props;

    const [inputValue, setInputValue] = useState<string>("");

    return (
      <div className={classNames(containerClassName)}>
        {!!name && label && (
          <Skeleton
            as={Label}
            htmlFor={id}
            loadingClassName="w-16"
            {...labelProps}
            className={classNames(
              labelClassName,
              labelSrOnly && "sr-only",
              props.error && "text-red-900"
            )}
          >
            {label}
          </Skeleton>
        )}
        {addOnLeading || addOnSuffix ? (
          <div className="relative mb-1 flex items-center rounded-md focus-within:outline-none focus-within:ring-2 focus-within:ring-neutral-800 focus-within:ring-offset-1">
            {addOnLeading && (
              <Addon
                isFilled={addOnFilled}
                className={classNames(
                  "rounded-l-md border-r-0",
                  addOnClassname
                )}
              >
                {addOnLeading}
              </Addon>
            )}
            <Input
              id={id}
              type={type}
              placeholder={placeholder}
              isFullWidth={inputIsFullWidth}
              className={classNames(
                className,
                addOnLeading && "rounded-l-none",
                addOnSuffix && "rounded-r-none",
                type === "search" && "pr-8",
                props.error && "border-red-900",
                "!my-0 !ring-0"
              )}
              {...passThrough}
              {...(type == "search" && {
                onChange: (e) => {
                  setInputValue(e.target.value);
                  props.onChange && props.onChange(e);
                },
                value: inputValue,
              })}
              readOnly={readOnly}
              ref={ref}
            />
            {addOnSuffix && (
              <Addon
                isFilled={addOnFilled}
                className={classNames(
                  "rounded-r-md border-l-0",
                  addOnClassname
                )}
              >
                {addOnSuffix}
              </Addon>
            )}
            {type === "search" && inputValue?.toString().length > 0 && (
              <FiX
                className="absolute top-2.5 h-4 w-4 cursor-pointer text-gray-500 ltr:right-2 rtl:left-2"
                onClick={(e) => {
                  setInputValue("");
                  props.onChange &&
                    props.onChange(
                      e as unknown as React.ChangeEvent<HTMLInputElement>
                    );
                }}
              />
            )}
          </div>
        ) : (
          <Input
            id={id}
            type={type}
            placeholder={placeholder}
            className={classNames(
              className, props.error && "border-red-900"
            )}
            {...passThrough}
            ref={ref}
            isFullWidth={inputIsFullWidth}
          />
        )}
        {!hideError && <HintsOrErrors hintErrors={hintErrors} fieldName={name} error={error} />}
        {hint && (
          <div className="text-gray mt-2 flex items-center text-sm text-gray-700">
            {hint}
          </div>
        )}
      </div>
    );
  }
);

type TextAreaProps = JSX.IntrinsicElements["textarea"];

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextAreaInput(props, ref) {
    return (
      <textarea
        ref={ref}
        {...props}
        className={classNames(
          "block w-full rounded-md border border-gray-300 py-2 px-3 text-sm hover:border-gray-400 focus:border-neutral-300 focus:outline-none focus:ring-2 focus:ring-neutral-800 focus:ring-offset-1",
          props.className
        )}
      />
    );
  }
);

type TextAreaFieldProps = {
  label?: ReactNode;
  hintErrors?: string[];
} & React.ComponentProps<typeof TextArea> & {
    name: string;
    labelProps?: React.ComponentProps<typeof Label>;
  };

export const TextAreaField = forwardRef<
  HTMLTextAreaElement,
  TextAreaFieldProps
>(function TextField(props, ref) {
  const id = useId();
  const methods = useFormContext();
  const {
    label,
    labelProps,
    /** Prevents displaying untranslated placeholder keys */
    placeholder,
    name,
    hintErrors,
    ...passThrough
  } = props;

  return (
    <div>
      {!!props.name && (
        <Label htmlFor={id} {...labelProps}>
          {label}
        </Label>
      )}
      <TextArea ref={ref} placeholder={placeholder} {...passThrough} />
      {methods?.formState?.errors[props.name]?.message && (
        <>
          <HintsOrErrors hintErrors={hintErrors} fieldName={name} />
        </>
      )}
    </div>
  );
});

import Link from "next/link";

// import { useHasPaidPlan } from "@billy/lib/hooks/useHasPaidPlan";

import { Tooltip } from "../tooltip";
import { Badge } from "./Badge";

export const UpgradeTeamsBadge = function UpgradeTeamsBadge() {
  // const { hasPaidPlan } = useHasPaidPlan();

  // if (hasPaidPlan) return null;

  return (
    <Tooltip content="upgrade_to_enable_feature">
      <Link href="/teams">
        <Badge variant="gray">Upgrade</Badge>
      </Link>
    </Tooltip>
  );
};

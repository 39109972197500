import { OrganizationSwitcher } from "@billy/features/organizations";
import classNames from "@billy/lib/classNames";
import { WEBAPP_URL } from "@billy/lib/constants";
import { trpc } from "@billy/trpc/react";
import {
  Button,
  Logo,
} from "@billy/ui";
import {
  FiLogOut,
  FiFile,
  FiPackage,
  FiSliders,
  FiList,
  FiGrid,
  FiArrowLeft,
  FiClipboard,
  FiArchive,
  FiHelpCircle,
  FiSettings,
} from "@billy/ui/components/icon";
import { signOut, useSession } from "next-auth/react";
import Link from "next/link";
import { NextRouter, useRouter } from "next/router";
import { ReactNode, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";

interface LayoutProps {
  heading?: ReactNode;
  subtitle?: ReactNode;
  children: ReactNode;
  CTA?: ReactNode;
  backPath?: string | boolean;
  isPublic?: boolean;
  simpleLayout?: boolean;
  bg?: string;
  classes?: string;
}

export type NavigationItemType = {
  name: string;
  href: string;
  badge?: React.ReactNode;
  icon?: any; //SVGComponent;
  child?: NavigationItemType[];
  pro?: true;
  onlyMobile?: boolean;
  onlyDesktop?: boolean;
  target?: string;
  isCurrent?: ({
    item,
    isChild,
    router,
  }: {
    item: NavigationItemType;
    isChild?: boolean;
    router: NextRouter;
  }) => boolean;
};

const navigation: NavigationItemType[] = [
  {
    name: "Dashboard",
    href: "/dashboard",
    icon: FiClipboard,
  },
  {
    name: "Submissions",
    href: "/submissions",
    icon: FiArchive,
  },
  {
    name: "Pages",
    href: "/pages",
    icon: FiFile,
  },
  {
    name: "Plans",
    href: "/plans",
    icon: FiPackage,
  },
  {
    name: "Components",
    href: "/components",
    icon: FiSliders,
  },
  {
    name: "Features",
    href: "/features",
    icon: FiList,
  },
  {
    name: "Apps",
    href: "/apps",
    icon: FiGrid,
  },
  {
    name: "Settings",
    href: "/settings",
    icon: FiSettings,
  },
];

const Navigation = () => {
  return (
    <nav className="mt-2 flex-1 space-y-1 md:px-2 lg:mt-5 lg:px-0">
      {navigation.map((item) => (
        <NavigationItem key={item.name} item={item} />
      ))}
    </nav>
  );
};

const footerNavigation: NavigationItemType[] = [
  {
    name: "Help",
    href: "https://help.billycpq.com",
    icon: FiHelpCircle,
    target: "_blank",
  },

];

const FooterNavigation = () => {
  return (
    <nav className="mt-2 flex-1 space-y-1 md:px-2 lg:mt-5 lg:px-0">
      {footerNavigation.map((item) => (
        <NavigationItem key={item.name} item={item} />
      ))}
    </nav>
  );
};

const defaultIsCurrent: NavigationItemType["isCurrent"] = ({
  isChild,
  item,
  router,
}) => {
  return isChild
    ? item.href === router.asPath
    : router.asPath.startsWith(item.href);
};

const NavigationItem: React.FC<{
  item: NavigationItemType;
  isChild?: boolean;
}> = (props) => {
  const { item, isChild } = props;
  const router = useRouter();
  const isCurrent: NavigationItemType["isCurrent"] =
    item.isCurrent || defaultIsCurrent;
  const current = isCurrent({ isChild: !!isChild, item, router });
  //const shouldDisplayNavigationItem = useShouldDisplayNavigationItem(props.item);

  //if (!shouldDisplayNavigationItem) return null;

  return (
    <>
      <Link
        href={item.href}
        target={item.target}
        aria-label={item.name}
        className={classNames(
          "group flex items-center rounded-md py-2 px-3 text-sm font-medium text-gray-600 hover:bg-gray-100 lg:px-[14px]  [&[aria-current='page']]:bg-gray-200 [&[aria-current='page']]:hover:text-neutral-900",
          isChild
            ? "[&[aria-current='page']]:text-brand-900 hidden pl-16 lg:flex lg:pl-11 [&[aria-current='page']]:bg-transparent"
            : "[&[aria-current='page']]:text-brand-900 "
        )}
        aria-current={current ? "page" : undefined}
      >
        {item.icon && (
          <item.icon
            className="h-4 w-4 flex-shrink-0 text-gray-500 mr-3 [&[aria-current='page']]:text-inherit"
            aria-hidden="true"
            aria-current={current ? "page" : undefined}
          />
        )}
        {item.name ? (
          <span className="hidden w-full justify-between lg:flex">
            <div className="flex">{item.name}</div>
            {item.badge && item.badge}
          </span>
        ) : (
          <></>
          // <SkeletonText className="h-3 w-32" />
        )}
      </Link>
      {item.child &&
        isCurrent({ router, isChild, item }) &&
        item.child.map((item) => (
          <NavigationItem key={item.name} item={item} isChild />
        ))}
    </>
  );
};

const SideBar = () => {
  const { data: session } = useSession();

  return (
    <aside className="desktop-transparent hidden w-14 flex-col border-r border-gray-100 bg-gray-50 md:flex lg:w-56 lg:flex-shrink-0 lg:px-4">
      <div className="flex h-0 flex-1 flex-col overflow-y-auto pt-3 pb-4 lg:pt-5">
        <header className="items-center justify-between md:hidden lg:flex">
          <Link className="px-4" href="/">
            <Logo />
          </Link>
        </header>

        <hr className="desktop-only absolute -left-3 -right-3 mt-4 block w-full border-gray-200" />

        {session?.user?.currentOrganization ? (
          <div className="mt-4">
            <OrganizationSwitcher />
          </div>
        ) : null}

        {session?.user?.currentOrganization ? <Navigation /> : null}
      </div>
      <div>
        <FooterNavigation />
        <Button
          color="minimal"
          className="w-full mt-2"
          StartIcon={(props) => (
            <FiLogOut
              className="h-4 w-4 flex-shrink-0 text-gray-500 mr-3 [&[aria-current='page']]:text-inherit"
              aria-hidden="true"
            />
          )}
          onClick={() => signOut({ callbackUrl: "/auth/logout" })}
        >
          Sign out
        </Button>
        <small className="mx-3 mt-1 mb-2 hidden text-[0.5rem] opacity-50 lg:block">
          &copy; {new Date().getFullYear()}{" "}
          <Link href="/" className="hover:underline">
            billycpq
          </Link>
        </small>
      </div>
    </aside>
  );
};

function useRedirectToLoginIfUnauthenticated(isPublic = false) {
  const { data: session, status } = useSession();
  const loading = status === "loading";
  const router = useRouter();

  useEffect(() => {
    if (isPublic) {
      return;
    }

    if (!loading && !session) {
      router.replace({
        pathname: "/auth/login",
        query: {
          callbackUrl: `${WEBAPP_URL}${location.pathname}${location.search}`,
        },
      });
    }

    // TO:DO need to be refactored
    // if (session && !session.user?.currentOrganization){
    //   router.push('/dashboard');
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, session, isPublic]);

  return {
    loading: loading && !session,
    session,
  };
}

const MainContainer = (props: LayoutProps) => {
  const { bg, classes } = props;
  const router = useRouter();

  const { session, loading } = useRedirectToLoginIfUnauthenticated(
    props.isPublic
  );

  if (!session && !props.isPublic) {
    return null;
  }

  if (loading) {
    return <div>loading</div>;
  }

  return (
    <main
      className={classNames(
        "relative z-0 flex flex-1 flex-col overflow-y-auto focus:outline-none",
        bg ? bg : "bg-white",
        classes
      )}
    >
      <div className="max-w-full px-4 py-2 lg:py-8 lg:px-12">
        <div className="flex items-baseline sm:mt-0">
          {!!props.backPath && (
            <Button
              size="icon"
              color="minimal"
              onClick={() =>
                typeof props.backPath === "string"
                  ? router.push(props.backPath as string)
                  : router.back()
              }
              StartIcon={FiArrowLeft}
              aria-label="Go Back"
              className="mr-2"
            />
          )}
          {props.heading && (
            <header
              className={classNames(
                "mb-4 flex w-full max-w-full items-center pt-4 md:p-0 lg:mb-10"
              )}
            >
              <div className="w-full ltr:mr-4 rtl:ml-4 sm:block">
                {props.heading && (
                  <h1 className="font-cal max-w-28 sm:max-w-72 md:max-w-80 mb-1 hidden truncate text-xl font-bold tracking-wide text-black sm:block xl:max-w-full">
                    {props.heading}
                  </h1>
                )}
                {props.subtitle && (
                  <p className="hidden text-sm text-neutral-500 sm:block">
                    {props.subtitle}
                  </p>
                )}
              </div>

              {props.CTA && (
                <div
                  className={classNames(
                    props.backPath
                      ? "relative"
                      : "fixed right-4 bottom-[75px] z-40 ",
                    "flex-shrink-0 sm:relative sm:bottom-auto sm:right-auto"
                  )}
                >
                  {props.CTA}
                </div>
              )}
            </header>
          )}
        </div>

        {props.children}
      </div>
    </main>
  );
};

const Layout = (props: LayoutProps) => {
  const { simpleLayout } = props;

  return (
    <div className="h-screen overflow-hidden">
      <div className="flex h-screen overflow-hidden">
        {simpleLayout ? (
          <MainContainer {...props} />
        ) : (
          <>
            <SideBar />
            <div className="flex w-0 flex-1 flex-col overflow-hidden">
              <MainContainer {...props} />
            </div>
          </>
        )}
      </div>
      <Toaster position="bottom-right" />
    </div>
  );
};

export default function Shell(props: LayoutProps) {
  return <Layout {...props}></Layout>;
}

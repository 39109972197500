import { CustomCheckboxSkeleton } from "./CustomCheckboxSkeleton";
import { classNames, formatPrice } from "@billy/lib";
import { getCurrencySymbol } from "@billy/lib/currencies";
import { useOrganization } from "@billy/lib/hooks/useOrganization";
import {
  PaymentModel,
  PricingModel,
  BillingPeriod,
} from "@billy/prisma/client";
import { Tooltip } from "@billy/ui";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import React, { forwardRef, InputHTMLAttributes } from "react";
import { FiInfo } from "react-icons/fi";

type Props = InputHTMLAttributes<HTMLInputElement> & {
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  label?: React.ReactNode;
  description?: string | React.ReactNode;
  informationIconText?: string;
  error?: boolean;
  classes?: string;
  tooltip?: string | React.ReactNode;
  component?: any;
  organization?: any;
};

const paymentModels: { [key in PaymentModel]: string } = {
  ONE_TIME: "ONE_TIME",
  RECURRING: "RECURRING",
};

const pricingModels: { [key in PricingModel]: string } = {
  STANDARD: "STANDARD",
  VOLUME: "VOLUME",
};

const billingPeriods: { [key in BillingPeriod]: string } = {
  MONTHLY: "MONTHLY",
  QUARTELY: "QUARTELY",
  SEMI_ANNUALLY: "SEMI_ANNUALLY",
  ANNUALLY: "ANNUALLY",
};

const formattedBillingPeriods: { [key in BillingPeriod]: string } = {
  MONTHLY: "month",
  QUARTELY: "every 3 months",
  SEMI_ANNUALLY: "every 6 months",
  ANNUALLY: "year",
};

const VolumeTooltip = (props: any) => {
  const { tiers, currencySymbol } = props;

  const TooltipContent = () => (
    <div className="p-1 text-xs">
      <div className="mb-1">Volume pricing</div>
      {tiers.map((tier: any, index: number) => (
        <div key={index}>
          {`${tier.firstUnit} - ${tier.lastUnit} / ${tier.unitAmount}`}
          {currencySymbol}
        </div>
      ))}
    </div>
  );

  return (
    <TooltipPrimitive.Provider>
      <Tooltip content={<TooltipContent />}>
        <span>
          <FiInfo className="inline-block ml-1" />
        </span>
      </Tooltip>
    </TooltipPrimitive.Provider>
  );
};

const CheckboxDescription = (props: any) => {
  const { component, currencySymbol } = props;

  const fBillignPeriod: any =
    formattedBillingPeriods[component?.billingPeriod] || "month";

  const lastIndicator =
    component?.paymentModel === paymentModels.RECURRING
      ? ` / ${fBillignPeriod}`
      : null;

  if (component.pricingModel === pricingModels.VOLUME) {
    const unitPrice = component?.metadata?.volumePricing.tiers[0].unitAmount;
    return (
      <div>
        {unitPrice}
        {currencySymbol}
        {" / "}
        {component.name}
        {lastIndicator}
        <VolumeTooltip
          tiers={component?.metadata?.volumePricing.tiers}
          currencySymbol={currencySymbol}
        />
      </div>
    );
  }

  const ReccuringLabel: any = () => {
    return component?.paymentModel === paymentModels.RECURRING
      ? ` / ${fBillignPeriod}`
      : null;
  };

  return (
    <div>
      <div>
        {formatPrice(component?.price)} {currencySymbol}
        <ReccuringLabel />
      </div>
    </div>
  );
};

const CustomCheckbox = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      description,
      error,
      disabled,
      prepend,
      append,
      classes,
      tooltip,
      component,
      organization,
      ...rest
    },
    ref
  ) => {
    const { currencySymbol, isLoading } = useOrganization(organization?.slug);

    if (isLoading) {
      return <CustomCheckboxSkeleton />;
    }

    return (
      <div className="relative flex items-start">
        {React.createElement(
          label ? "label" : "div",
          {
            className: classNames(
              "relative flex items-start select-none cursor-pointer hover:bg-gray-50",
              !error && label ? "text-gray-900" : "text-gray-900",
              error && "text-red-800",
              classes
            ),
            ...(!label
              ? {
                  htmlFor: rest.id,
                }
              : {}),
          },
          <>
            <div className="flex items-center">
              {tooltip ? (
                <TooltipPrimitive.Provider>
                  <Tooltip content={tooltip}>
                    {prepend && <span>{prepend}</span>}
                  </Tooltip>
                </TooltipPrimitive.Provider>
              ) : (
                prepend && <span>{prepend}</span>
              )}
              <span>
                <div className="text-sm">{label}</div>
                <div className="text-xs text-gray-500">
                  <CheckboxDescription
                    component={component}
                    currencySymbol={currencySymbol}
                  />
                </div>
              </span>
            </div>
            <div className="flex h-5 items-center">
              <input
                {...rest}
                ref={ref}
                type="checkbox"
                disabled={disabled}
                className={classNames(
                  "text-primary-600 focus:ring-primary-500 h-4 w-4 rounded border-gray-300 ltr:mr-2 rtl:ml-2 ",
                  !error && disabled
                    ? "bg-gray-300 checked:bg-gray-300"
                    : "checked:bg-gray-800 hover:bg-gray-100",
                  error && "border-red-800 checked:bg-red-800 hover:bg-red-400"
                )}
              />
              {append}
            </div>
          </>
        )}
      </div>
    );
  }
);

CustomCheckbox.displayName = "CustomCheckbox";

export default CustomCheckbox;

import React from "react";

export function CustomCheckboxSkeleton() {
  return (
    <div className="animate-pulse relative flex items-center px-4 py-4 sm:px-6 h-full w-full border border-neutral-200 rounded mb-3">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center justify-between">
          <span className="inline-block">
            <span className="block h-6 w-6 mr-2 bg-gray-300 rounded"></span>
          </span>
          <div className="grid gap-2">
            <span className="block w-[100px] h-2 bg-gray-300 rounded"></span>
            <span className="block w-[100px] h-1 bg-gray-300 rounded"></span>
          </div>
        </div>
        <div className="flex h-5 items-center">
          <span className="inline-block h-4 w-4 rounded border border-gray-300"></span>
        </div>
      </div>
    </div>
  );
}

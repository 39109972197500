import { classNames } from "@billy/lib";

export type SwatchProps = {
  size?: "base" | "sm" | "lg";
  backgroundColor: string;
  onClick: () => void;
};

const Swatch = (props: SwatchProps) => {
  const { size, backgroundColor, onClick } = props;
  return (
    <div className="border border-gray-300 border-r-0 pl-2 py-2 h-[38px] flex items-center rounded-l-md">
      <div
        onClick={onClick}
        style={{ backgroundColor }}
        className={classNames(
          "cursor-pointer",
          size === "sm" && "h-4 w-4 rounded-sm",
          size === "base" && "h-16 w-16 rounded-sm",
          size === "lg" && "h-24 w-24 rounded-sm"
        )}
      />
    </div>
  );
};

export default Swatch;

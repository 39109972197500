import { getCurrencySymbol } from "@billy/lib/currencies";
import { trpc } from "@billy/trpc/react";

export const useOrganization = (slug: string) => {
  const { data: organization, isLoading } = trpc.viewer.organizations.getBySlug.useQuery(
    {
      slug,
    },
    { enabled: !!slug }
  );

  if (isLoading) {
    return {
      isLoading
    }
  }

  if (!organization) {
    return {};
  }

  return {
    isLoading,
    name: organization?.name,
    slug: organization?.slug,
    currencySymbol: getCurrencySymbol(organization?.currency),
  };
};

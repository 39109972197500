import Link from "next/link";
import { FunctionComponent } from "react";

function SideBar() {
  return (
    <aside className="desktop-transparent hidden w-14 flex-col border-r border-gray-100 bg-gray-50 md:flex lg:w-56 lg:flex-shrink-0 lg:px-4">
      <div className="flex h-0 flex-1 flex-col overflow-y-auto pt-3 pb-4 lg:pt-5">
        <header className="items-center justify-between md:hidden lg:flex">
          <Link className="px-4" href="/event-types">
            billy
          </Link>
        </header>
      </div>
    </aside>
  );
}

const AppLayout: FunctionComponent = ({ children }) => {
  return (
    <div className="h-screen overflow-hidden">
      <div className="flex h-screen overflow-hidden">
        <SideBar />
        <div className="flex w-0 flex-1 flex-col overflow-hidden">
          <main className="relative z-0 flex flex-1 flex-col overflow-y-auto bg-white focus:outline-none">
            <div className="max-w-full px-4 py-2 lg:py-8 lg:px-12">
              {children}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default AppLayout;

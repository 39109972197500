import { Button } from "@billy/ui";
import { AnimatePresence, motion } from "framer-motion";
import Head from "next/head";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";

const accordionData = [
  {
    id: 1,
    header: "Show Your Value Proposition",
    description:
      "Our intuitive package builder lets customers define their ideal package based on their needs and lets your sales team explain your value propositions.",
  },
  {
    id: 2,
    header: "Streamlined Sales Process",
    description:
      "Simplify closing deals by reducing the number of steps between a product demonstration and the signing giving your sales team more time to focus on building relationships and closing deals.",
  },
  {
    id: 3,
    header: "Increase Your Average Basket",
    description:
      "Through our unique way of presenting your features and/or services, your customers can better perceive your value proposition and thus increase your sales.",
  },
  {
    id: 4,
    header: "Sales Enablement",
    description:
      "Provide your sales with relevant information and improve your sales strategy with our powerful analysis, reporting tools, and recommendations.",
  },
];

const Accordion = ({ i, expanded, setExpanded }) => {
  const isOpen = i.id === expanded;

  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <motion.div
      className="px-6 py-6 rounded-lg cursor-pointer"
      animate={{ backgroundColor: isOpen ? "#FFAB05" : "#ffffff" }}
      onClick={() => setExpanded(i.id)}
    >
      <motion.header
        className="text-lg font-articulat font-bold mb-3 relative pr-[30px]"
        initial={false}
      >
        {i.header}
        <AnimatePresence initial={false}>
          <motion.div
            key="arrow"
            animate={{
              rotate: isOpen ? 180 : 0,
              transition: { duration: 0.3, ease: "easeIn" },
            }}
            className="absolute top-0 right-0"
          >
            <Image src="/home/arrow.svg" alt="Billy" width={28} height={28} />
          </motion.div>
        </AnimatePresence>
      </motion.header>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            className="relative overflow-hidden font-articulat text-sm"
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
          >
            {i.description}
          </motion.section>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

function Index() {
  const [carouselWith, setCarouselWidth] = useState(0);
  const carousel = useRef<any>({ scrollWidth: 0, offsetWidth: 0 });

  const [expanded, setExpanded] = useState<false | number>(1);

  useEffect(() => {
    setCarouselWidth(
      carousel?.current?.scrollWidth - carousel?.current?.offsetWidth
    );
  }, []);

  return (
    <>
      <Head>
        <title>Billy - Modern CPQ for SaaS</title>
        <meta property="og:image" content="https://www.billycpq.com/api/og" />
        <meta
          name="description"
          content="Empower your sales team to Close Deals Faster and Boost Your Average Basket. CPQ (Configure, Price & Quote) designed for SaaS Companies."
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff"></meta>
      </Head>
      <div className="bg-hero-pattern bg-[center_top] bg-repeat-x">
        <header className="py-5 absolute left-0 right-0 top-0 w-full">
          <div className="max-w-[1230px] w-full m-auto px-4">
            <div className="flex justify-between">
              <Image src="logo.svg" alt="Billy" width={133} height={38} />
              <Button
                href="https://tally.so/r/mDBjaN"
                target="_blank"
                rel="nofollow"
              >
                Request Demo
              </Button>
            </div>
          </div>
        </header>

        <section className="md:pt-[145px] pb-[40px] md:pb-[170px]">
          <div className="max-w-[1230px] w-full m-auto px-4">
            <div className="lg:flex">
              <div className="pt-[85px] flex-1">
                <h1 className="text-[42px] md:text-[62px] leading-none mb-6 font-cerebri">
                  Make the sales process smarter.
                </h1>
                <p className="mb-6 font-articulat">
                  Billy is the modern version of CPQ (Configure, Price & Quote)
                  designed for SaaS Companies. It helps you to empower your
                  sales team to close deals faster, speed up your quoting
                  process and increase your average basket.
                </p>
                <Button
                  href="https://tally.so/r/mDBjaN"
                  target="_blank"
                  rel="nofollow"
                  className="bg-[#FFAB05] hover:bg-[#000000] !text-black hover:!text-white px-10 py-4 !h-auto hidden md:inline-flex"
                >
                  Request Demo
                </Button>
              </div>
              <div className="flex justify-end items-center">
                <Image
                  src="/home/hero-img.webp"
                  alt="Billy"
                  width={677}
                  height={487}
                  className="h-auto m-auto"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="pb-[20px] md:pb-[75px]">
          <div className="max-w-[1230px] w-full m-auto px-4">
            <h2 className="text-center text-[30px] md:text-[36px] font-articulat mb-12">
              Who is using Billy?
            </h2>
            <motion.div ref={carousel} className="overflow-hidden">
              <motion.div
                drag="x"
                dragConstraints={{
                  right: 0,
                  left: -carouselWith,
                }}
                className="flex gap-4"
              >
                <motion.div className="min-h-[250px] min-w-[280px] md:min-w-[32%] border b-gray-600 p-5 rounded-3xl flex justify-between flex-col">
                  <div>
                    <div className="min-h-[60px] flex items-center">
                      <Image
                        src="/home/logo-da.png"
                        alt="da"
                        width={46}
                        height={60}
                        className="grayscale"
                      />
                    </div>
                    <p className="text-base font-articulat text-[#696969] my-8">
                      Thanks to this tool, we were able to showcase the full
                      value proposition of our SaaS product and increase our
                      average basket size by 23,8%. Our sales team was able to
                      focus on closing deals rather than spending hours on
                      creating quotes. Highly recommended at the start and at
                      the end !
                    </p>
                  </div>
                  <div>
                    <Image
                      src="/home/da-gm.png"
                      alt="da gm"
                      width={50}
                      height={50}
                      className="mb-3"
                    />
                    <p className="text-sm text-black font-articulat font-bold">
                      Justin Barthelemy
                    </p>
                    <p className="text-sm text-[#696969] font-articulat">
                      Country Manager at Doctoranytime
                    </p>
                  </div>
                </motion.div>
                <motion.div className="min-h-[250px] min-w-[280px] md:min-w-[32%] border b-gray-600 p-5 rounded-3xl flex justify-between flex-col">
                  <div>
                    <div className="min-h-[60px] flex items-center">
                      <Image
                        src="/home/logo-raizume.png"
                        alt="da"
                        width={96}
                        height={37}
                        className="grayscale"
                      />
                    </div>
                    <p className="text-base font-articulat text-[#696969] my-8">
                      As the Head of Sales, I can confidently say that this tool
                      has transformed the way we do business. Our sales team is
                      more efficient, effective and has been able to focus on
                      what they do best - closing deals. Highly recommend giving
                      it a try!
                    </p>
                  </div>
                  <div>
                    <Image
                      src="/home/izysea-gm.png"
                      alt="raizume gm"
                      width={50}
                      height={50}
                      className="mb-3"
                    />
                    <p className="text-sm text-black font-articulat font-bold">
                      Luc Garchette
                    </p>
                    <p className="text-sm text-[#696969] font-articulat">
                      Head of Sales at Raizume
                    </p>
                  </div>
                </motion.div>
                <motion.div className="min-h-[250px] min-w-[280px] md:min-w-[32%] border b-gray-600 p-5 rounded-3xl flex justify-between flex-col">
                  <div>
                    <div className="min-h-[60px] flex items-center">
                      <Image
                        src="/home/logo-tipaw.png"
                        alt="da"
                        width={95}
                        height={36}
                        className="grayscale"
                      />
                    </div>

                    <p className="text-base font-articulat text-[#696969] my-8">
                      If you&apos;re looking for a way to simplify quote
                      creation and improve your sales results, this tool is a
                      must-have. We were able to streamline our sales process
                      and close deals faster than ever before.
                    </p>
                  </div>
                  <div>
                    <Image
                      src="/home/tipaw-gm.png"
                      alt="tipaw gm"
                      width={50}
                      height={50}
                      className="mb-3"
                    />
                    <p className="text-sm text-black font-articulat font-bold">
                      Pierre Moens
                    </p>
                    <p className="text-sm text-[#696969] font-articulat">
                      CEO at Tipaw
                    </p>
                  </div>
                </motion.div>
              </motion.div>
            </motion.div>
          </div>
        </section>
      </div>

      <section className="py-[40px] md:py-[75px]">
        <div className="max-w-[1230px] w-full m-auto px-4">
          <h2 className="text-center text-[30px] md:text-[36px] font-articulat mb-6">
            Discover Our Benefits
          </h2>
          <p className="text-[#696969] font-articulat max-w-[1000px] w-full m-auto text-center">
            We provide an all-in-one solution that streamlines signing, speed up
            quote creation, and showcases the full value proposition of your
            software products. Our mission is to help you close deals more
            efficiently and increase your average basket by empowering your
            sales team with a modern, customizable, and qualitative closing
            journey.
          </p>

          <div className="mt-16 flex">
            <div className="max-w-[380px]">
              {accordionData.map((i) => (
                <Accordion
                  key={i.id}
                  i={i}
                  expanded={expanded}
                  setExpanded={setExpanded}
                />
              ))}
            </div>
            <div className="flex-1 hidden md:block">
              <div className="aspect-[16/9] relative">
                <AnimatePresence mode="wait">
                  {accordionData.map((i) => {
                    return (
                      expanded === i.id && (
                        <motion.div
                          key={i.id}
                          className="absolute top-0 left-0 right-0 bottom-0 m-auto"
                          initial="collapsed"
                          animate="open"
                          exit="collapsed"
                          variants={{
                            open: { y: 0, opacity: 1 },
                            collapsed: { y: -10, opacity: 0 },
                          }}
                        >
                          <Image
                            src={`/home/accordion-${i.id}.webp`}
                            alt="feature 1"
                            width={678}
                            height={421}
                            className="m-auto"
                          />
                        </motion.div>
                      )
                    );
                  })}
                </AnimatePresence>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-[75px] bg-[#EAEDEF]">
        <div className="max-w-[1230px] w-full m-auto px-4">
          <h2 className="text-center text-[30px] md:text-[36px] font-articulat mb-6">
            How should you use it
          </h2>
          <div className="grid gap-4 md:grid-cols-3 text-center">
            <div>
              <div className="w-[60px] h-[60px] bg-[#FFAB05] m-auto mb-5 rounded-full flex items-center justify-center">
                <Image
                  src="/home/number-1.svg"
                  alt="1"
                  width={15}
                  height={25}
                />
              </div>
              <h3 className="text-[22px] font-articulat mb-5">
                Define your Features
              </h3>
              <p className="font-articulat text-[#696969]">
                List up all your different features, options add-ons and
                packages so you can easily show your value proposition !
              </p>
            </div>
            <div>
              <div className="w-[60px] h-[60px] bg-[#FFAB05] m-auto mb-5 rounded-full flex items-center justify-center">
                <Image
                  src="/home/number-2.svg"
                  alt="1"
                  width={20}
                  height={25}
                />
              </div>
              <h3 className="text-[22px] font-articulat mb-5">
                Create your Components and Plans
              </h3>
              <p className="font-articulat text-[#696969]">
                Generate the components and plans that will emphasize the power
                of your service.
              </p>
            </div>
            <div>
              <div className="w-[60px] h-[60px] bg-[#FFAB05] m-auto mb-5 rounded-full flex items-center justify-center">
                <Image
                  src="/home/number-3.svg"
                  alt="1"
                  width={16}
                  height={23}
                />
              </div>
              <h3 className="text-[22px] font-articulat mb-5">
                Close your Deals
              </h3>
              <p className="font-articulat text-[#696969]">
                Create your pages, connect them with your CRM and let your sales
                team do the magic!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-[75px]">
        <div className="max-w-[1230px] w-full m-auto px-4">
          <h2 className="text-center text-[30px] md:text-[36px] font-articulat mb-6">
            Integrates Seamlessly With Your Tech Stack
          </h2>
          <p className="text-[#696969] font-articulat max-w-[1000px] w-full m-auto text-center">
            At Billy, we believe in a new approach to selling SaaS solutions.
            Our cutting-edge platform offers a modernized CPQ experience that
            caters to the needs of SaaS companies. By understanding your needs
            and offering a custom solution integrated with your favorite tools,
            we help sales teams achieve better results and redefine the sales
            process.
          </p>
          <div className="flex flex-wrap md:grid md:gap-4 md:grid-cols-5 text-center mt-20 items-center">
            <div className="w-1/2 mb-8 md:w-auto md:mb-0">
              <Image
                src="/home/hubspot.svg"
                alt="1"
                width={120}
                height={30}
                className="mx-auto"
              />
            </div>
            <div className="w-1/2 mb-8 md:w-auto md:mb-0">
              <Image
                src="/home/asana.svg"
                alt="1"
                width={112}
                height={22}
                className="mx-auto"
              />
            </div>
            <div className="w-1/2 mb-8 md:w-auto md:mb-0">
              <Image
                src="/home/google.svg"
                alt="1"
                width={106}
                height={34}
                className="mx-auto"
              />
            </div>
            <div className="w-1/2 mb-8 md:w-auto md:mb-0">
              <Image
                src="/home/slack.svg"
                alt="1"
                width={112}
                height={29}
                className="mx-auto"
              />
            </div>
            <div className="w-full mb-8 md:w-auto md:mb-0">
              <Image
                src="/home/stripe.svg"
                alt="1"
                width={86}
                height={36}
                className="mx-auto"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="py-[40px] md:py-[75px] bg-[#FFAB05] text-white">
        <div className="max-w-[1230px] w-full m-auto px-4">
          <h2 className="text-center text-[30px] md:text-[36px] font-articulat mb-6">
            Join the Revolution
          </h2>
          <p className="text-white font-articulat max-w-[940px] w-full m-auto text-center">
            Ready to take your sales process to the next
            level? Try Billy for free today and discover how our modern CPQ
            platform can help you revolutionize your SaaS sales strategy.
            Empower your sales team, increase your average basket, and transform
            the way you sell with Billy.
          </p>
          <div className="text-center mt-10">
            <Button
              href="https://tally.so/r/mDBjaN"
              target="_blank"
              rel="nofollow"
              className="text-lg md:px-6 py-2 !h-auto rounded-full"
            >
              Request demo
            </Button>
          </div>
        </div>
      </section>

      <footer className="py-10">
        <div className="max-w-[1230px] w-full m-auto px-4">
          <div className="flex justify-center">
            <Image src="logo.svg" alt="Billy" width={133} height={38} />
          </div>
        </div>
      </footer>

      <footer className="py-4 border-t border-[#C9D3DD]">
        <div className="max-w-[1230px] w-full m-auto px-4">
          <p className="text-xs">billycpq. All right reserved. &copy; 2023</p>
        </div>
      </footer>
    </>
  );
}

export default Index;

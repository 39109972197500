import classNames from "@billy/lib/classNames";
import { WEBAPP_URL } from "@billy/lib/constants";
import { trpc } from "@billy/trpc/react";
import {
  Button,
  Dropdown,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@billy/ui";
import {
  FiLogOut,
  FiFile,
  FiPackage,
  FiSliders,
  FiList,
  FiGrid,
  FiSettings,
  FiUsers,
  FiArrowLeft,
  FiFolder,
  FiPlus,
} from "@billy/ui/components/icon";
import { signOut, useSession } from "next-auth/react";
import Link from "next/link";
import { NextRouter, useRouter } from "next/router";
import { ReactNode, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";

interface LayoutProps {
  heading?: ReactNode;
  subtitle?: ReactNode;
  children: ReactNode;
  CTA?: ReactNode;
  backPath?: string | boolean;
  isPublic?: boolean;
  bg?: string;
  classes?: string;
  organization?: any;
  sidebarContent?: ReactNode;
}

export type NavigationItemType = {
  name: string;
  href: string;
  badge?: React.ReactNode;
  icon?: any; //SVGComponent;
  child?: NavigationItemType[];
  pro?: true;
  onlyMobile?: boolean;
  onlyDesktop?: boolean;
  isCurrent?: ({
    item,
    isChild,
    router,
  }: {
    item: NavigationItemType;
    isChild?: boolean;
    router: NextRouter;
  }) => boolean;
};

const navigation: NavigationItemType[] = [
  {
    name: "Pages",
    href: "/pages",
    icon: FiFile,
  },
  {
    name: "Plans",
    href: "/plans",
    icon: FiPackage,
  },
  {
    name: "Components",
    href: "/components",
    icon: FiSliders,
  },
  {
    name: "Features",
    href: "/features",
    icon: FiList,
  },
  {
    name: "Apps",
    href: "/apps",
    icon: FiGrid,
  },
  {
    name: "Settings",
    href: "/settings",
    icon: FiSettings,
  },
];

const Navigation = () => {
  return (
    <nav className="mt-2 flex-1 space-y-1 md:px-2 lg:mt-5 lg:px-0">
      {navigation.map((item) => (
        <NavigationItem key={item.name} item={item} />
      ))}
    </nav>
  );
};

const footerNavigation: NavigationItemType[] = [
  {
    name: "Organizations",
    href: "/organizations",
    icon: FiUsers,
  },
];

const FooterNavigation = () => {
  return (
    <nav className="mt-2 flex-1 space-y-1 md:px-2 lg:mt-5 lg:px-0">
      {footerNavigation.map((item) => (
        <NavigationItem key={item.name} item={item} />
      ))}
    </nav>
  );
};

const defaultIsCurrent: NavigationItemType["isCurrent"] = ({
  isChild,
  item,
  router,
}) => {
  return isChild
    ? item.href === router.asPath
    : router.asPath.startsWith(item.href);
};

const NavigationItem: React.FC<{
  item: NavigationItemType;
  isChild?: boolean;
}> = (props) => {
  const { item, isChild } = props;
  const router = useRouter();
  const isCurrent: NavigationItemType["isCurrent"] =
    item.isCurrent || defaultIsCurrent;
  const current = isCurrent({ isChild: !!isChild, item, router });
  //const shouldDisplayNavigationItem = useShouldDisplayNavigationItem(props.item);

  //if (!shouldDisplayNavigationItem) return null;

  return (
    <>
      <Link
        href={item.href}
        aria-label={item.name}
        className={classNames(
          "group flex items-center rounded-md py-2 px-3 text-sm font-medium text-gray-600 hover:bg-gray-100 lg:px-[14px]  [&[aria-current='page']]:bg-gray-200 [&[aria-current='page']]:hover:text-neutral-900",
          isChild
            ? "[&[aria-current='page']]:text-brand-900 hidden pl-16 lg:flex lg:pl-11 [&[aria-current='page']]:bg-transparent"
            : "[&[aria-current='page']]:text-brand-900 "
        )}
        aria-current={current ? "page" : undefined}
      >
        {item.icon && (
          <item.icon
            className="h-4 w-4 flex-shrink-0 text-gray-500 mr-3 [&[aria-current='page']]:text-inherit"
            aria-hidden="true"
            aria-current={current ? "page" : undefined}
          />
        )}
        {item.name ? (
          <span className="hidden w-full justify-between lg:flex">
            <div className="flex">{item.name}</div>
            {item.badge && item.badge}
          </span>
        ) : (
          <></>
          // <SkeletonText className="h-3 w-32" />
        )}
      </Link>
      {item.child &&
        isCurrent({ router, isChild, item }) &&
        item.child.map((item) => (
          <NavigationItem key={item.name} item={item} isChild />
        ))}
    </>
  );
};

const SideBar = ({ children }: any) => {
  return (
    <aside className="desktop-transparent hidden w-14 flex-col md:flex lg:w-[360px] lg:flex-shrink-0 lg:px-4">
      <div className="sticky top-[20px] mt-[40px]">
        {children}
      </div>
    </aside>
  );
};

function useRedirectToLoginIfUnauthenticated(isPublic = false) {
  const { data: session, status } = useSession();
  const loading = status === "loading";
  const router = useRouter();

  useEffect(() => {
    if (isPublic) {
      return;
    }

    if (!loading && !session) {
      router.replace({
        pathname: "/auth/login",
        query: {
          callbackUrl: `${WEBAPP_URL}${location.pathname}${location.search}`,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, session, isPublic]);

  return {
    loading: loading && !session,
    session,
  };
}

const MainContainer = (props: LayoutProps) => {
  const { bg, classes } = props;
  const router = useRouter();

  const { session, loading } = useRedirectToLoginIfUnauthenticated(
    props.isPublic
  );

  if (!session && !props.isPublic) {
    return null;
  }

  if (loading) {
    return <div>loading</div>;
  }

  return (
    <main
      className={classNames(
        "relative z-0 flex flex-1 flex-col overflow-y-auto focus:outline-none",
        bg ? bg : "bg-white",
        classes
      )}
    >
      <div className="max-w-full px-4 py-4">{props.children}</div>
    </main>
  );
};

const Header = (props: LayoutProps) => {
  const { organization } = props;
  return (
    <header className="flex bg-gray-50 px-4 py-4">
      <div>{organization?.name}</div>
      <div></div>
    </header>
  );
};

const Footer = (props: LayoutProps) => {
  return (
    <small className="mx-3 mt-1 mb-2 hidden text-[0.5rem] opacity-50 lg:block">
      &copy; {new Date().getFullYear()}{" "}
      <Link href="/" className="hover:underline">
        BillyApp
      </Link>
    </small>
  );
};

const Layout = (props: LayoutProps) => {
  return (
    <>
      <div className="mx-auto max-w-[1200px]">
        <Header {...props} />
        <MainContainer {...props} />
        <Footer {...props} />
      </div>
      <Toaster position="bottom-right" />
    </>
  );
};

export default function PublicShell(props: LayoutProps) {
  return <Layout {...props}></Layout>;
}

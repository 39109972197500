export const EURO = "euro";
export const USD = "usd";

export const currencies: any = {
  [EURO]: {
    name: "euro",
    symbol: "€",
    iso: "EUR",
  },
  [USD]: {
    name: "usd",
    symbol: "$",
    iso: "USD",
  },
};

export function getCurrencySymbol(name: string) {
  return currencies[name].symbol;
}

export const allCurrenciesOptions = [
  {
    label: currencies[EURO].symbol,
    value: currencies[EURO].name,
  },
  {
    label: currencies[USD].symbol,
    value: currencies[USD].name,
  },
];

export function formatPrice(price: number = 0, decimals = false) {
  return decimals ? (Math.abs(price) / 100).toFixed(2) : Math.abs(price) / 100;
}
